<template>
  <div>
    <v-expansion-panels mb="3">
      <v-expansion-panel v-for="order in orderList" :key="order.id">
        <v-expansion-panel-header>
          <v-row>
            <v-col>
              <div>
                <p class="ReferenceOperatorListTableHeader">ФИО</p>
                {{ order.clientName }}
              </div>
            </v-col>
            <!-- <v-col>
              <div>
                <p class="ReferenceOperatorListTableHeader">Почта</p>
                {{ order.clientEmail }}
              </div>
            </v-col> -->
            <v-col>
              <div>
                <p class="ReferenceOperatorListTableHeader">Телефон</p>
                {{ order.clientPhone }}
              </div>
            </v-col>
            <!-- <v-col>
              <div>
                <p class="ReferenceOperatorListTableHeader">Заказано</p>
                {{ formatDate(order.createdAt) }}
              </div>
            </v-col> -->
            <v-col>
              <div>
                <p class="ReferenceOperatorListTableHeader">Статус</p>
                {{ order.status.nameRu }}
              </div>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div>Заказ № {{ order.orderNum }}</div>
          <br />
          <CourierOrderItem :order="order" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import ViewHelper from '@/helpers/view.helper';
import ReferenceHelper from '@/helpers/reference/reference.helper';
import CourierOrderItem from '@/components/courier/CourierOrderItem';
export default {
  props: {
    orderList: {
      requierd: true
    }
  },
  components: {
    CourierOrderItem
  },
  data() {
    return {};
  },
  methods: {
    formatDate: ViewHelper.formatDate,

    getStatus(reference) {
      return ReferenceHelper.getOrderStatus(reference.status.id);
    }
  }
};
</script>
