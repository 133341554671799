<template>
  <div>
    <v-btn color="#e21a1a" v-on:click="dialog = true" depressed>
      <v-icon color="white" left>mdi-arrow-right-bottom</v-icon>
      <span class="ReferenceOperatorWorkButtonText">ВЗЯТЬ В РАБОТУ</span>
    </v-btn>
    <v-dialog v-model="dialog" max-width="1280" persistent>
      <v-card class="file-upload-card" outlined>
        <v-form ref="productForm">
          <v-card-title class="pb-4">Заказ</v-card-title>
          <v-row>
            <v-col>
              <v-card-title>Состав заказа</v-card-title>
              <v-row>
                <v-col class="d-flex mb-0">
                  <v-card-text>Позиция</v-card-text>
                  <v-card-text>Количество</v-card-text>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row v-for="item in order.items" :key="item.token">
                <v-col class="d-flex mb-0 py-0">
                  <v-card-text>{{ item.nameRu }}</v-card-text>
                  <v-card-text>{{ item.ItemOrder.quantity }}</v-card-text>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-col>
            <v-col>
              <v-card-title class="py-2 my-2">Персональные данные</v-card-title>
              <!-- <v-row v-for="item in getReferenceFields" :key="item.id">
                <v-col
                  class="d-flex mb-0 py-0"
                  v-if="item.type !== 'takeoutId' && item.type !== 'cafeId' && item.type !== 'clientId' && item.type !== 'statusId'"
                >
                  <v-card-text> {{ item.name }}: </v-card-text>
                  <v-card-text> {{ order[item.in] }}</v-card-text>
                </v-col>
                <v-col class="d-flex mb-0 py-0" v-if="item.type === 'takeoutId'">
                  <v-card-text> {{ item.name }}: </v-card-text>
                  <v-card-text> {{ takeoutsName }}</v-card-text>
                </v-col>
                <v-col class="d-flex mb-0 py-0" v-if="item.type === 'cafeId'">
                  <v-card-text> {{ item.name }}: </v-card-text>
                  <v-card-text> {{ cafeName }}</v-card-text>
                </v-col>
                <v-col class="d-flex mb-0 py-0" v-if="item.type === 'statusId'">
                  <v-card-text> {{ item.name }}: </v-card-text>
                  <v-card-text> {{ statusName }}</v-card-text>
                </v-col>
              </v-row> -->
              <v-row class="pa-0 ma-0">
                <v-col class="pa-0 ma-0">
                  <v-card-text class="py-1 my-1"> Станция: {{ order.takeout.station.nameExpFullRu }}</v-card-text>
                </v-col>
              </v-row>
              <v-row class="pa-0 ma-0">
                <v-col class="pa-0 ma-0">
                  <v-card-text class="py-1 my-1"> Точка выдачи: {{ order.takeout.nameFullRu }} </v-card-text>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-card-actions class="mb-2 d-flex flex-wrap">
            <v-btn v-if="order.status.id !== 100" text class="mr-auto mx-2 text-none my-1" color="primary" @click="openConfirmDialog">ОТМЕНИТЬ ЗАКАЗ</v-btn> -->
            <v-btn :disabled="order.status.id !== 30" class="white--text my-1" depressed color="#e21a1a" @click="deliveryOrderClick">ЗАБРАЛ ЗАКАЗ</v-btn>
            <v-btn :disabled="order.status.id !== 31" class="white--text my-1" depressed color="#e21a1a" @click="awaitOrderClick">ОЖИДАЮ КЛИЕНТА</v-btn>
            <v-btn :disabled="order.status.id !== 40" class="white--text my-1" color="#e21a1a" @click="completeOrderClick">ЗАВЕРШИТЬ</v-btn>
            <v-btn text class="mx-2 text-none my-1" color="grey darken-3" @click="closeDialog">ЗАКРЫТЬ</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="cancelReference" max-width="1024" persistent>
      <v-card class="file-upload-card pa-4" outlined>
        <div>
          Вы действительно хотите отменить заявку? <br />
          Обязанность возврата денег и связь с клиентом ложится на оператора
        </div>
        <!-- <v-text-field outlined :label="editOrder.mail" v-model="cancelEmail"></v-text-field> -->
        <v-text-field outlined label="Причина" v-model="cancelReason"></v-text-field>
        <div v-if="this.isCancelError">Не удалось отменить заявку: {{ this.cancelMessage }}</div>
        <v-card-actions class="d-flex flex-row-reverse">
          <v-btn type="submit" depressed class="mr-3 text-RussianRail" v-on:click="closeCancelDialog">Вернуться к заполнению</v-btn>
          <v-btn
            type="submit"
            depressed
            color="#e21a1a"
            :loading="this.isCancelLoading"
            class="white--text mr-3 text-RussianRail"
            v-on:click="confirmCancelReference"
            >Отменить заявку</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="notification">
      <Notification />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Notification from '@/components/Notification';

export default {
  props: {
    order: {
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      cancelReason: '',
      cancelReference: false
    };
  },
  components: { Notification },

  computed: {
    ...mapGetters('order', ['awaitMessage']),
    ...mapGetters('order', ['deliveryMessage']),
    ...mapGetters('order', ['completeMessage']),
    ...mapGetters('order', ['cancelMessage']),
    ...mapGetters('order', ['isCancelLoading']),
    ...mapGetters('order', ['isCancelError'])
  },
  methods: {
    awaitOrderClick() {
      this.awaitOrder({ id: this.order.id })
        .then(() => {
          console.log();
          this.closeDialog();
          this.showNotification('Успешно', 'success', '2000', 'mdi-check');
        })
        .catch(() => {
          this.showErrorNotification(`Возникла ошибка: ${this.awaitMessage}`, 'red', '2000', 'mdi-alert-circle-outline');
        });
    },
    deliveryOrderClick() {
      this.deliveryOrder({ id: this.order.id })
        .then(() => {
          this.closeDialog();
          this.showNotification('Успешно', 'success', '2000', 'mdi-check');
        })
        .catch(() => {
          this.showErrorNotification(`Возникла ошибка: ${this.deliveryMessage}`, 'red', '2000', 'mdi-alert-circle-outline');
        });
    },
    completeOrderClick() {
      this.completeOrder({ id: this.order.id })
        .then(() => {
          this.closeDialog();
          this.showNotification('Успешно', 'success', '2000', 'mdi-check');
        })
        .catch(() => {
          this.showErrorNotification(`Возникла ошибка: ${this.completeMessage}`, 'red', '2000', 'mdi-alert-circle-outline');
        });
    },
    async confirmCancelReference() {
      try {
        const data = { id: this.order.id, message: this.cancelReason };
        if (this.cancelEmail !== undefined && this.cancelEmail !== null && this.cancelEmail.length > 0) {
          data.mail = this.cancelEmail;
        }
        await this.cancelOrder(data);
        this.closeCancelDialog();
        this.closeDialog();
        this.showNotification('Успешно', 'success', '2000', 'mdi-check');
      } catch (error) {
        this.showErrorNotification(`Возникла ошибка: ${this.cancelMessage}`, 'red', '2000', 'mdi-alert-circle-outline');
      }
    },
    closeDialog() {
      this.dialog = false;
      this.updateOrderList();
      // if (this.type === 'kitchen') {
      //   this.fetchOrdersForKitchen();
      // } else {
      //   this.fetchReferenceList();
      // }
      // this.clearOrderTimer();
    },
    closeCancelDialog() {
      this.cancelReference = false;
    },
    openConfirmDialog() {
      this.cancelReference = true;
    },
    updateOrderList() {
      this.fetchOrdersForCourier();
    },
    showNotification(message, color, timer, icon) {
      this.showSnack({
        message,
        color,
        timer,
        icon
      });
    },
    showErrorNotification(message, color, timer, icon) {
      this.showSnack({
        message,
        color,
        timer,
        icon
      });
    },
    ...mapActions('courier', ['fetchOrdersForCourier']),
    ...mapActions('order', ['awaitOrder']),
    ...mapActions('order', ['deliveryOrder']),
    ...mapActions('order', ['completeOrder']),
    ...mapActions('order', ['cancelOrder']),

    ...mapActions('notification', ['showSnack'])
  }
};
</script>
